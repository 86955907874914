import React from 'react';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Coil from '../../Assets/products/coil/coils.png';
import AllinOne from '../../Assets/products/coil/3in1.png';
import Refill from '../../Assets/products/coil/electric.png';
import Sachet from '../../Assets/products/coil/sachet.png';
import Lotion from '../../Assets/products/coil/lotion.png';
import MatMachine from '../../Assets/products/coil/mat-machine.png';
import ProductBg from '../../Assets/backgroundVideo/product-detail-bg.png';
import Productbanners from '../../Components/ProductBanners/Productbanners';
import CoilRepellentBg from '../../Assets/banners/CoilRepellent.jpg'
import CoilRepellentMobileBg from '../../Assets/banners/coil-&-repellent-mobile.jpg'

function CoilRepllenet() {
    const products = [
        {
            id: 'cobra-coil-10s-jumbo',
            name: 'Cobra Coil 10’S JUMBO',
            image: Coil,
            description: 'Khushboo se machhar huye zakhmi',
        },
        {
            id: 'cobra-liquid-destroyer-3-in-1-combo-machine',
            name: 'COBRA LIQUID DESTROYER 3 IN 1 COMBO',
            image: AllinOne,
            description: 'Machharon ki tabahi he tabahi',
        },
        {
            id: 'cobra-plug-in-refill',
            name: 'COBRA PLUG IN REFILL',
            image: Refill,
            description: 'Stopped? Why? Ran out of repellent? Here, change the refill and restart!!',
        },
        {
            id: 'cobra-mosquito-repllent-sachet',
            name: 'COBRA MOSQUITO REPLLENT SACHET',
            image: Sachet,
            description: 'Repel & relax – Goodbye mosquitoes!',
        },
        {
            id: 'cobra-mosquito-repllent-lotion',
            name: 'COBRA MOSQUITO REPLLENT LOTION',
            image: Lotion,
            description: 'Repel & relax – Goodbye mosquitoes!',
        },
        {
            id: 'cobra-mat-machine',
            name: 'COBRA MAT MACHINE',
            image: MatMachine,
            description: 'An electrifying killer',
        },
    ];

    return (
        <>
            <Productbanners img={CoilRepellentBg} mobileImg={CoilRepellentMobileBg} />
            <div className="product-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 justify-content-center d-flex">
                            <div className="title">
                                Coil & Repellent
                                <div className="underline">
                                    <div className="style1"></div>
                                    <div className="style2"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 text-center mb-5">
                            <div className="para">
                                Kill the beast which keeps you awake at night
                            </div>
                        </div>
                        {products.map(product => (
                            <div key={product.id} className="col-lg-3">
                                <div className="product-card">
                                    <div
                                        className="product-img"
                                        style={{
                                            backgroundImage: `url(${ProductBg})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center center',
                                        }}
                                    >
                                        <img src={product.image} alt={product.name} />
                                    </div>
                                    <div className="product-content">
                                        <h3>{product.name}</h3>
                                        <p>{product.description}</p>
                                    </div>
                                    <div className="product-btn">
                                        <Button as={Link} className="btn-red d-block ms-5 me-5" to={`/products/${product.id}`}>
                                            View Details
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CoilRepllenet;
