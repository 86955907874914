import React, { useState } from 'react';
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb';
import GalleryComp from '../Components/GalleryComp/GalleryComp';
import GalleryBg from '../Assets/backgroundVideo/gallery.jpg'

// Import all images
import img1 from '../Assets/gallery/event1/1.jpg';
import img2 from '../Assets/gallery/event1/2.jpg';
import img3 from '../Assets/gallery/event1/3.jpg';
import img4 from '../Assets/gallery/event1/4.jpg';
import img5 from '../Assets/gallery/event1/5.jpg';
import img6 from '../Assets/gallery/event1/6.jpg';
import img7 from '../Assets/gallery/event2/1.jpg';
import img8 from '../Assets/gallery/event2/2.jpg';
import img9 from '../Assets/gallery/event2/3.jpg';
import img10 from '../Assets/gallery/event3/1.jpg';
import img11 from '../Assets/gallery/event3/2.jpg';
import img12 from '../Assets/gallery/event3/3.jpg';
import img13 from '../Assets/gallery/event3/4.jpg';
import img14 from '../Assets/gallery/event3/5.jpg';
import img15 from '../Assets/gallery/event3/6.jpg';
import img23 from '../Assets/gallery/event3/7.jpg';
import img16 from '../Assets/gallery/event4/1.jpg';
import img17 from '../Assets/gallery/event4/2.jpg';
import img18 from '../Assets/gallery/event4/3.jpg';
import img22 from '../Assets/gallery/event4/4.jpg';
import img19 from '../Assets/gallery/event5/3.jpg';
import img20 from '../Assets/gallery/event5/2.jpg';
import img21 from '../Assets/gallery/event5/1.jpg';

function Gallery() {
    const [mainImage, setMainImage] = useState(img1);

    const event1 = [img1, img2, img3, img4, img5, img6];
    const event2 = [img7, img8, img9];
    const event3 = [img10, img11, img12, img13, img14, img15, img23];
    const event4 = [img16, img17, img18, img22];
    const event5 = [img19, img20, img21];

    const handleThumbnailClick = (index) => {
        switch (index) {
            case 0:
                setMainImage(img1);
                break;
            case 1:
                setMainImage(img2);
                break;
            case 2:
                setMainImage(img3);
                break;
            // Add cases for other indices
            default:
                setMainImage(img1);
                break;
        }
    };

    return (
        <>
            <Breadcrumb pageName="Gallery" img={GalleryBg}/>
            <div className='gallery-section mt-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6  d-flex justify-content-center flex-column">
                            <div className="gallery-content">
                                <div className="title">Prize Distribution Event</div>
                                <p className="para">We celebrated our achievement of reaching the best sales target by hosting a delightful dinner and holding a prize distribution event. At the Clock Tower, we distributed tokens of appreciation and shared our joy.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 border d-flex align-items-center p-3 mt-sm-3">
                            <div className="image-carousel">
                                <GalleryComp event={event1} handleThumbnailClick={handleThumbnailClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='gallery-section '>
                <div className="container">
                    <div className="row reverseOrder">
                        <div className="col-lg-6 border d-flex align-items-center p-3 mt-sm-3">
                            <div className="image-carousel">
                                <GalleryComp event={event2} handleThumbnailClick={handleThumbnailClick} />
                            </div>
                        </div>
                        <div className="col-lg-6  d-flex justify-content-center flex-column ps-4">
                            <div className="gallery-content">
                                <div className="title">Hum Masala Exhibition</div>
                                <p className="para">At the Hum Masala Exhibition, Cobra Hygiene Insecticides captivated visitors with their innovative solutions for pest control in kitchen and food storage areas.Cobra’s expertise in insecticides provided valuable insights for maintaining cleanliness and food safety standards in homes and commercial kitchens alike.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='gallery-section'>
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6  d-flex justify-content-center flex-column">
                            <div className="gallery-content">
                                <div className="title">Grand Exhibition</div>
                                <p className="para">At My Karachi, Cobra Hygiene Insecticides showcased their products as essential tools for maintaining cleanliness. Our participation underscored the importance of pest control and hygiene practices in urban environments, contributing into promoting a healthy and sustainable city. Visitors learned about Cobra’s effective solutions for pest management and appreciated their commitment to enhancing living standards in Karachi.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 border d-flex align-items-center p-3 mt-sm-3">
                            <div className="image-carousel">
                                <GalleryComp event={event3} handleThumbnailClick={handleThumbnailClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='gallery-section '>
                <div className="container">
                    <div className="row reverseOrder">
                        <div className="col-lg-6 border d-flex align-items-center p-3 mt-sm-3">
                            <div className="image-carousel">
                                <GalleryComp event={event4} handleThumbnailClick={handleThumbnailClick} />
                            </div>
                        </div>
                        <div className="col-lg-6  d-flex justify-content-center flex-column ps-4">
                            <div className="gallery-content">
                                <div className="title">Showcase Of Products</div>
                                <p className="para">Our products were showcased at the Golf Club, where we presented them to potential clients and partners.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='gallery-section mb-5'>
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6  d-flex justify-content-center flex-column">
                            <div className="gallery-content">
                                <div className="title">Silver Jubilee Exhibition</div>
                                <p className="para">Cobra Hygiene marked its Silver Jubilee with an impressive exhibition of products in international expo, demonstrating 25 years of excellence. We introduced our effective products to the global market and received an overwhelmingly positive response.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 border d-flex align-items-center p-3 mt-sm-3">
                            <div className="image-carousel">
                                <GalleryComp event={event5} handleThumbnailClick={handleThumbnailClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Gallery;