import React from 'react'
import Statements from '../Components/Statements/Statements'
import Slider from '../Components/Slider/Slider'
import Info from '../Components/Info/Info'
import Categories from '../Components/Categories/Categories'
import Aim from '../Components/Aim/Aim'
import Testimonials from '../Components/Testimonials/Testimonials'
import './style.css'
import InspiringStory from '../Assets/inspiring-story.png'

function Home() {
    return (
        <>
            <Slider />
            <div className="boxes-section">
                <div className="container">
                    <Statements />
                </div>
            </div>
            <div className="inspiring-story-section">
                <div className="container">
                    <div className="col-12 justify-content-center d-flex desktop-padding">
                        <h1 className="title text-center mb-5">COBRA INSPIRING STORY
                            <div className="underline">
                                <div className="style1"></div>
                                <div className="style2"></div>
                            </div>
                        </h1>
                    </div>
                    <Info
                        subtitle={"SUCCESSFUL YET LONG JOURNEY"}
                        para1={"This journey started in 1988 by the sturdiest, strongest and genius man who was very determined to establish and grow this business all over Pakistan and make people’s lives easy and that person is none other than Mr. Shaikh Abdul Hameed (Late). He was very positive that Uneek Corporation could be a big hit and he was not wrong at all, later it emerged into Cobra which is a leading brand until now. He set the trend when nobody was even aware that these products can become one of the necessities of day to day life, he brought the change but it did not happen overnight, he struggled a lot just to make cobra what it is today. His sons played a vital role in this journey and made father’s success become true by being unshaken pillars."}
                        para2={"Cobra Hygiene (Pvt) Ltd is one of the leading manufacturer, importer & distributor of household, self-care, baby care & insecticides in Pakistan. It has created its own brands which are considered as leading brands in their respective categories."}
                        para3={"Cobra Hygiene is one of the best and oldest companies which was built to bring the drastic change on a national level where consumers are satisfied, safe, and living a healthy life. Cobra completed its ultimate plan, and will keep doing so."}
                        img={InspiringStory}
                    />
                </div>
            </div>
            <div className="categories-section">
                <div className="container">
                    <div className="col-12 justify-content-center d-flex">
                        <h1 className="title text-center mb-5">Our Categories
                            <div className="underline">
                                <div className="style1"></div>
                                <div className="style2"></div>
                            </div>
                        </h1>
                    </div>
                    <Categories />
                </div>
            </div>
            <div className="aim-section">
                <div className="container">
                    <div className="col-12 justify-content-center d-flex">
                        <h1 className="title text-center mb-5">What Stands Us Out?
                            <div className="underline">
                                <div className="style1"></div>
                                <div className="style2"></div>
                            </div>
                        </h1>
                    </div>
                    <Aim />
                </div>
            </div>
            <div className="reviews-section">
                <div className="container">
                    <div className="col-12 justify-content-center d-flex">
                        <h1 className="title text-center mb-">Testimonials
                            <div className="underline">
                                <div className="style1"></div>
                                <div className="style2"></div>
                            </div>
                        </h1>
                    </div>
                    <Testimonials />
                </div>
            </div>
        </>
    )
}

export default Home