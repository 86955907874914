import React from 'react'
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function FooterInfo() {

    const data = [
        {
            icon: faMapMarkerAlt,
            heading: "Find us",
            info: "41/1, Block-3 Opposite Asma Homes, N.K.C.H.S, Behind Dolmen Mall, Tariq Road, Karachi"
        },
        {
            icon: faPhone,
            heading: "Call us",
            info: "+92 316 2892230"
        },
        {
            icon: faEnvelope,
            heading: "Mail us",
            info: "cobrahygiene@gmail.com"
        }
    ]
    return (
        <>
            {data.map((item, index) => (
                <div className="col-xl-4 col-md-4 mb-30 pb-sm-10" key={index}>
                    <div className="single-cta d-flex">
                        <FontAwesomeIcon icon={item.icon} className='icons' />
                        <div className="cta-text">
                            <h4>{item.heading}</h4>
                            <span> {item.info} </span>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default FooterInfo