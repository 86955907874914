import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function FooterLinks() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <div className="footer-widget-heading">
                <h3>Useful Links</h3>
            </div>
            <div className='footer-links'>
                <ul>
                    <li>
                        <Link to={'/'} >Home</Link>
                    </li>
                    <li>
                        <Link to={"/Products"}>Products</Link>
                    </li>
                    <li>
                        <Link to={"/Gallery "}>Gallery</Link>
                    </li>
                    <li>
                        <Link to={"/Media"}>Media</Link>
                    </li>
                    <li>
                        <Link to={"/Export"}>Export</Link>
                    </li>
                    <li>
                        <Link to={"/Contat"}>Contact us</Link>
                    </li>

                </ul>
                <ul>
                    <li>
                        <Link to={"/Products/Baby-Care "}>Baby Care</Link>
                    </li>
                    <li>
                        <Link to={"/Products/Household-Stuff"}>Household Stuff</Link>
                    </li>
                    <li>
                        <Link to={"/Products/Insect-Killer"}>Insect Killer</Link>
                    </li>
                    <li>
                        <Link to={"/Products/Coil-&-Repellent"}>Coil & Repellent</Link>
                    </li>
                    <li>
                        <Link to={"/Products/Self-Care"}>Self Care</Link>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default FooterLinks;
