import React from 'react'
import './Footer.css'
import Logo from '../../Assets/logo.png'
import FooterLinks from './FooterLinks/FooterLinks'
import { Link } from 'react-router-dom'
import FooterInfo from './FooterInfo/FooterInfo'
import FooterAbout from './FooterAbout/FooterAbout'
import FooterSubscibe from './FooterSubscribe/FooterSubscibe'

function Footer() {
    return (
        <>
            <div className="container">
                <div className="footer-cta">
                    <div className="row">
                        <FooterInfo />
                    </div>
                </div>
                <div className="footer-content mt-4">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 mb-50">
                            <FooterAbout logo={Logo} />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div className="footer-widget mobile-pl">
                                <FooterLinks />
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                            <div className="footer-widget mobile-pl">
                                <FooterSubscibe />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 text-lg-left">
                            <div className="copyright-text">
                                <p>Designed & Developed By <Link target='_blank' to={"https://iisol.pk/"}><b>IISOL</b></Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer