import React from 'react';
import './Breadcrumb.css'
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

function Breadcrumb(props) {
  return (
    <div className="bgImg"  style={{ backgroundImage: `url(${props.img})` }}>
      <div role="presentation" onClick={handleClick} className='breadcrumb m-0'>
        <Breadcrumbs aria-label="breadcrumb" separator='>' color="#fff" fontSize={"30px"}>
          <Link underline="hover" color="#fff" fontSize={'30px'} href="/">
            Home
          </Link>
          <Typography color="#fff" fontSize={'30px'}>{props.pageName}</Typography>
        </Breadcrumbs>
      </div>
    </div>
  );
}

export default Breadcrumb;
