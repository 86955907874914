import { faAward, faCircleCheck, faHandshake } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function Aim() {
    const data = [
        {
            icon: faHandshake,
            title: "Trusted",
            para: "Cobra Hygiene (PVT) Ltd, is one of the leading manufacturers, importer and distributor of household, personal care products in Pakistan. It has created its own brands which are considered as leading brands into respective categories."
        },
        {
            icon: faAward,
            title: "Quality",
            para: "Cobra Hygiene (PVT) Ltd, serving its customers with the maximum effective, impressive, eye-catching, amazing quality and luxurious products. Company has a large customer base all across Pakistan who use the products with great satisfaction."
        },
        {
            icon: faCircleCheck,
            title: "Excellence",
            para: "Since 1988, Cobra Hygiene (Pvt) Ltd has been in the industry and with its rapid movement of development in the import, marketing and consumption of different products in our company, turned the company into a paradise for such goods."
        }
    ]

    return (

        <div className='row'>
        {data.map((item, index) => (
            <div className="col-lg-4 mb-3" key={index}>
                <div className='box-content'>
                <FontAwesomeIcon icon={item.icon} size='3x' className='boxes-icons'/>
                <h2>{item.title}</h2>
                <p>{item.para}</p>
            </div>
            </div>
        ))}
    </div>
    )
}

export default Aim