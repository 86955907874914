import React from 'react'
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb'
import ExportImg from './../Assets/export.jpg'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Export() {

    const data = [
        {
            title: "OUR FUTURE EXPORT ENDEAVOR",
            para: "As Cobra Hygiene looks ahead, we are gearing up for an exciting journey to expand our reach beyond borders. With a legacy of excellence spanning 40 years, we are poised to introduce our high-quality products to international markets, particularly in the Middle East, Africa, and Asia."
        },
        {
            title: "WHY EXPORT WITH US?",
            para: "Partnering with Cobra Hygiene means aligning with a trusted brand committed to cleanliness and safety. Our meticulously crafted products, including Self & Baby Care Products, Insecticides, and COBRA household items, adhere to the highest standards, ensuring customer satisfaction and loyalty."
        },
        {
            title: "LOCAL EXPERTISE, GLOBAL VISION",
            para: "Understanding the intricacies of global markets, we leverage our expertise to navigate regulations, logistics, and cultural nuances. Through strategic alliances and distribution networks, we envision seamless access to our products worldwide, fostering enduring relationships with customers and partners alike."
        },
        {
            title: "JOIN THE EXPORT REVOLUTION",
            para: "Are you prepared to seize new opportunities and expand your horizons in international markets? We invite you to embark on this journey with us. Contact Cobra Hygiene today to explore partnership opportunities and play a pivotal role in our future global success story."
        }
    ]
    return (
        <>
            <Breadcrumb pageName={"Export"} img={ExportImg} />
            <div className="container mt-5 mb-5 ">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="box-content">
                            <h2 className='my-3'>COBRA HYGIENE’S EXPORT VISION</h2>
                            <p className="para">
                                Welcome to Cobra Hygiene’s Export Vision page. As a trusted name in hygiene solutions for four decades, Cobra Hygiene is excited to announce our plans to expand into international markets in the near future, particularly in the vibrant regions of the Middle East, Africa, and Asia.
                            </p>
                        </div>

                    </div>
                </div>


                <div className="row">
                    {data.map(item =>
                        <div className="col-lg-6 my-3">
                            <div className="box-content">
                                <h2 className='my-3'>{item.title}</h2>
                                <p className="para">
                                    {item.para}
                                </p>
                            </div>

                        </div>
                    )}

                    <div className="col-lg-2 col-sm-12 me-auto ms-auto mt-3 text-center">
                    <Button as={Link} to={"/Contact"} className='btn btn-red'>Inquire Now</Button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Export