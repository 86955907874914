import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import Aerosal from '../../Assets/products/InsectKiller/aerosel-insect-killer.png';
import ExtraStrongAerosal from '../../Assets/products/InsectKiller/extra-strong-aerosel.png';
import KillerPowder from '../../Assets/products/InsectKiller/powder.png';
import Bomb from '../../Assets/products/InsectKiller/3in1.png';
import Insecticide from '../../Assets/products/InsectKiller/insecticide.png';
import Lizard from '../../Assets/products/InsectKiller/lizard.png';
import ProductBg from '../../Assets/backgroundVideo/product-detail-bg.png';
import InsectKillerBg from '../../Assets/banners/insectkiller.jpg'
import InsectKillerMobileBg from '../../Assets/banners/insect-killer-mobile.jpg'
import Productbanners from '../../Components/ProductBanners/Productbanners';

function InsectKiller() {
    const products = [
        {
            id: 'cobra-extra-aerosol-insect-killer',
            name: 'COBRA EXTRA AEROSOL INSECT KILLER',
            image: Aerosal,
            description: 'Keep your house hygienic & germs free',
        },
        {
            id: 'cobra-extra-strong-aerosol-insect-killer',
            name: 'COBRA EXTRA STRONG AEROSOL INSECT KILLER',
            image: ExtraStrongAerosal,
            description: 'Silently but kill extremely deadly',
        },
        {
            id: 'cobra-powder-insect-killer',
            name: 'COBRA POWDER INSECT KILLER',
            image: KillerPowder,
            description: 'Har kone main thoda thoda chore naw keeron ka nishaan bhi zara sa',
        },
        {
            id: 'bomb-aerosol-insect-killer',
            name: 'BOMB AEROSOL INSECT KILLER',
            image: Bomb,
            description: `Target locked! 123 GO!!!.....BOOM…The rivals are finished can't tease you anymore!!!`,
        },
        {
            id: 'cobra-aerosol-lizard-killer',
            name: 'COBRA AEROSOL LIZARD KILLER',
            image: Lizard,
            description: 'Scared to even look at?? Don’t worry just spray it around this creepy creature will be dead in no time!',
        },
        {
            id: 'cobra-xtreme-insecticide-spray',
            name: 'COBRA XTREME 7X- INSECTICIDE SPRAY',
            image: Insecticide,
            description: 'Keera ho ya makora ya ho kaan main bhinbhinane wale machar sb ka khatma with Cobra Xtreme. Extremely deadly!!!',
        },
    ];

    return (
        <>
            <Productbanners img={InsectKillerBg} mobileImg={InsectKillerMobileBg} />
            <div className="product-section">
                <div className="container">
                    <div className='row'>
                        <div className="col-12 justify-content-center d-flex">
                            <div className="title">
                                Insect Killer
                                <div className="underline">
                                    <div className="style1"></div>
                                    <div className="style2"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 text-center mb-5">
                            <div className="para">
                                Get rid of these creepy creatures In the best hygienic way possible
                            </div>
                        </div>
                        {products.map(product => (
                            <div key={product.id} className="col-lg-3">
                                <div className="product-card">
                                    <div
                                        className="product-img"
                                        style={{
                                            backgroundImage: `url(${ProductBg})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center center',
                                        }}
                                    >
                                        <img src={product.image} alt={product.name} />
                                    </div>
                                    <div className="product-content">
                                        <h3>{product.name}</h3>
                                        <p>{product.description}</p>
                                    </div>
                                    <div className="product-btn">
                                        <Button as={Link} className='btn-red d-block ms-5 me-5' to={`/products/${product.id}`}>View Details</Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default InsectKiller;
