import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import mockProducts from '../mockData/mockProducts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft, faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ProductDetail() {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const productDetails = mockProducts.find(product => product.id === productId);
                if (!productDetails) {
                    throw new Error('Product not found');
                }
                setProduct(productDetails);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [productId]);

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };

    const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % product.images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((currentIndex - 1 + product.images.length) % product.images.length);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return <div>Product not found</div>;
    }

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        // slidesToScroll: 1,   
        // autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <FontAwesomeIcon icon={faLongArrowLeft} className='prevBtn p-0' />,
        nextArrow: <FontAwesomeIcon icon={faLongArrowRight} className=' nextBtn' />,
        dots: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]

    };

    const arrowStyle = {
        position: 'absolute',
        top: '35%',
        transform: 'translateY(-50%)',
        zIndex: '999',
        fontSize: '24px',
        cursor: 'pointer',
    };

    return (
        <>
            <div role="presentation" className='product-detail-breadcrumb' onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/" className='text-center'>
                        Home
                    </Link>
                    <Link className='text-center' to={`/Products/${product.category}`}>
                        {product.category}
                    </Link>
                    <Typography color="text.primary">{product.name}</Typography>
                </Breadcrumbs>
            </div>
            <div className="product-detail-section">
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-6">
                            <div className="product-detail d-flex justify-content-center align-items-center position-relative">
                                <div className="product-gallery">

                                    <div className="main-image">
                                        {product.images && product.images.length > 0 ? (
                                            <>
                                                <img src={product.images[currentIndex]} alt={product.name} className="img-fluid" />
                                            </>
                                        ) : (
                                            <img src={product.image} alt={product.name} className="img-fluid" />
                                        )}
                                    </div>


                                    <div className="thumbnail-images">
                                        {product.images && product.images.length > 0 && (
                                            <div className="thumbnail-images">
                                                <Slider {...settings}>
                                                    {product.images.map((image, index) => (
                                                        <div className="thumbnail-image" key={index}>
                                                            <img
                                                                src={image}
                                                                alt={product.name}
                                                                className={`${currentIndex === index ? 'active' : ''}`}
                                                                onClick={() => handleThumbnailClick(index)}
                                                            />
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-sm-3">
                            <div className="product-detail">
                                <h2 className='title'>{product.name}</h2>
                                <ol style={{ listStyle:'circle' }}>
                                {product.tagline && <li><h6>{product.tagline}</h6></li> }
                                {product.tagline2 && <li><h6>{product.tagline2}</h6></li> }
                                {product.tagline3 && <li><h6>{product.tagline3}</h6></li> }
                                </ol>
                                <br />
                                <hr />
                                <div className="description">
                                    <h2>Description</h2>
                                    <p>{product.description}</p>
                                    <p>
                                        <strong>SKU:</strong> <span>{product.sku}</span>
                                    </p>
                                    <p>
                                        <strong>Category:</strong> <span><Link to={`/Products/${product.category}`} className='btn'>{product.category}</Link></span>
                                    </p>

                                </div>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <div className="section-tabs pb-0">
                        <Tabs>
                            <TabList>
                                <Tab>How To Use</Tab>
                                <Tab>Precautions</Tab>
                                <Tab>Size</Tab>
                                <Tab>Colors</Tab>
                            </TabList>

                            <TabPanel>
                                <div className="tab-content">
                                    <h4>How To Use</h4>
                                    <ul className='ps-5'>
                                        {product.use && product.use.point1 && <li>{product.use.point1}</li>}
                                        {product.use && product.use.point2 && <li>{product.use.point2}</li>}
                                        {product.use && product.use.point3 && <li>{product.use.point3}</li>}
                                        {product.use && product.use.point4 && <li>{product.use.point4}</li>}
                                        {product.use && product.use.point5 && <li>{product.use.point5}</li>}
                                    </ul>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="tab-content">
                                    <h4>Precautions</h4>
                                    <ul className='ps-5'>
                                        {product.precaution && product.precaution.point1 && <li>{product.precaution.point1}</li>}
                                        {product.precaution && product.precaution.point2 && <li>{product.precaution.point2}</li>}
                                        {product.precaution && product.precaution.point3 && <li>{product.precaution.point3}</li>}
                                        {product.precaution && product.precaution.point4 && <li>{product.precaution.point4}</li>}
                                        {product.precaution && product.precaution.point5 && <li>{product.precaution.point5}</li>}
                                    </ul>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <table className='table table-bordered table-stripe table-hover mt-4'>
                                    <tbody>
                                        <tr>
                                            <th style={{ background: '#ed020d', }} className='text-white text-center w-50'>Size</th>
                                            <th style={{ background: '#ed020d', }} className='text-white text-center w-50'>Carton Pack</th>
                                        </tr>
                                        {product.table.size && product.table.cartonPack && (
                                            <tr>
                                                <td className='text-center'>{product.table.size}</td>
                                                <td className='text-center'>{product.table.cartonPack}</td>
                                            </tr>
                                        )}
                                        {product.table.size2 && product.table.cartonPack2 && (
                                            <tr>
                                                <td className='text-center'>{product.table.size2}</td>
                                                <td className='text-center'>{product.table.cartonPack2}</td>
                                            </tr>
                                        )}
                                        {product.table.size3 && product.table.cartonPack3 && (
                                            <tr>
                                                <td className='text-center'>{product.table.size3}</td>
                                                <td className='text-center'>{product.table.cartonPack3}</td>
                                            </tr>
                                        )}
                                        {product.table.size4 && product.table.cartonPack4 && (
                                            <tr>
                                                <td className='text-center'>{product.table.size4}</td>
                                                <td className='text-center'>{product.table.cartonPack4}</td>
                                            </tr>
                                        )}
                                        {product.table.size5 && product.table.cartonPack5 && (
                                            <tr>
                                                <td className='text-center'>{product.table.size5}</td>
                                                <td className='text-center'>{product.table.cartonPack5}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel>
                                <div className="tab-content">
                                    <h4>Color / Fragrances</h4>
                                    <div className="color-fragrance d-flex gap-2 mt-4">
                                        {product.type1 && <p className='btn-red text-white' style={{ borderRadius: '5px' }}>{product.type1}</p>}
                                        {product.type2 && <p className='btn-red text-white' style={{ borderRadius: '5px' }}>{product.type2}</p>}
                                        {product.type3 && <p className='btn-red text-white' style={{ borderRadius: '5px' }}>{product.type3}</p>}
                                        {product.type4 && <p className='btn-red text-white' style={{ borderRadius: '5px' }}>{product.type4}</p>}
                                        {product.type5 && <p className='btn-red text-white' style={{ borderRadius: '5px' }}>{product.type5}</p>}
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetail;
