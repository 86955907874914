import React, { useState } from 'react';
import Select from 'react-select';

const MultiSelect = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    { value: 'Cobra Xtreme Bed Bug Killer', label: 'Cobra Xtreme Bed Bug Killer' },
    { value: 'Cobra Coil 10’s Jumbo', label: 'Cobra Coil 10’s Jumbo' },
    { value: 'Cobra Liquid Destroyer 3 in 1 Combo Machine', label: 'Cobra Liquid Destroyer 3 in 1 Combo Machine' },
    { value: 'Cobra Plug in Refill', label: 'Cobra Plug in Refill' },
    { value: 'Cobra Mosquito Repllent Sachet', label: 'Cobra Mosquito Repllent Sachet' },
    { value: 'Cobra Mosquito Repllent Lotion', label: 'Cobra Mosquito Repllent Lotion' },
    { value: 'Cobra Antilice Shampoo', label: 'Cobra Antilice Shampoo' },
    { value: 'Cobra Head Louse & Nit Spray', label: 'Cobra Head Louse & Nit Spray' },
    { value: 'Cobra Aerosol Insect Killer', label: 'Cobra Aerosol Insect Killer' },
    { value: 'Cobra Aerosol Lizard Killer', label: 'Cobra Aerosol Lizard Killer' },
    { value: 'Cobra Extra Strong Aerosole Insect Killer', label: 'Cobra Extra Strong Aerosole Insect Killer' },
    { value: 'Cobra Xtreme 7X- Insecticide Spray', label: 'Cobra Xtreme 7X- Insecticide Spray' },
    { value: 'Cobra Powder Insect Killer', label: 'Cobra Powder Insect Killer' },
    { value: 'Bomb Aerosol Insect Killer', label: 'Bomb Aerosol Insect Killer' },
    { value: 'Coil & Repellent', label: 'Coil & Repellent' },
    { value: 'Cobra Xtreme 7x', label: 'Cobra Xtreme 7x' },
    { value: 'Shinax Furniture Polish', label: 'Shinax Furniture Polish' },
    { value: 'Cobra Extreme Glue', label: 'Cobra Extreme Glue' },
    { value: 'Cobra Toilet Bowl Cleaner', label: 'Cobra Toilet Bowl Cleaner' },
    { value: 'Cobra Toilet Bowl Cleaner Tablet', label: 'Cobra Toilet Bowl Cleaner Tablet' },
    { value: 'Bebe Soap', label: 'Bebe Soap' },
    { value: 'Bebe - Cloth Wipes', label: 'Bebe - Cloth Wipes' },
    { value: 'Cobra Antilice Shampoo - Satche', label: 'Cobra Antilice Shampoo - Satche' },
    { value: 'KuKu Herbal Soap', label: 'KuKu Herbal Soap' },
    { value: 'Ku Ku Prickly Powder', label: 'Ku Ku Prickly Powder' }
  ];


  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  return (
    <>
      <Select
        isMulti
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        required
        name='user_product'
        placeholder="Select product"
      />
      <div className="invalid-feedback ">*please select at least one option.</div>
    </>
  );
};

export default MultiSelect;
