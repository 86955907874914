import React from 'react'
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb'
import ContactImg from '../Assets/backgroundVideo/contact.jpg'
import ContactForm from '../Components/Contact/ContactForm'

function Contact() {
    return (
        <>
            <Breadcrumb pageName="Contact Us" img={ContactImg}  />
            <div className="map mb-5">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.6254012511968!2d67.06005937443027!3d24.87664054461175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33eecaef72355%3A0x3c9cca44c77de888!2sDolmen%20Mall%20Tariq%20Road!5e0!3m2!1sen!2s!4v1710143852556!5m2!1sen!2s" width="100%" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className='contact-section position-relative'>
                <div className="container d-flex justify-content-center align-items-center">
                    <ContactForm />
                </div>
            </div>
        </>
    )
}

export default Contact