import React from 'react'
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import MediaBg from  '../Assets/backgroundVideo/media.jpg'

function Media() {
    const data = [
        {
            title: "COBRA INS TVC (1)",
            para: "These stubborn, harmful mosquitoes will die with double speed and none will be left for you to worry about.",
            video: <iframe className='customIframe' width="560" height="315" src="https://www.youtube.com/embed/eYMXEsU4NDg?si=jyAXyxWiHsgzMXU3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        },
        {
            title: "COBRA INS TVC (2)",
            para: "These stubborn, harmful mosquitoes will die with double speed and none will be left for you to worry about.",
            video: <iframe className='customIframe' width="560" height="315" src="https://www.youtube.com/embed/Z0LtxYmJpNw?si=NX3ofD4p9kvEN075" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        },
        {
            title: "COBRA INS TVC (3)",
            para: "These stubborn, harmful mosquitoes will die with double speed and none will be left for you to worry about.",
            video: <iframe className='customIframe' width="560" height="315" src="https://www.youtube.com/embed/3wRzLyQdKG0?si=Z6olCd0BfhIKH5pU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        },
    ]
    return (
        <>
            <Breadcrumb pageName="Media" img={MediaBg}  />
            <div className='video-section'>
                <div className="container">
                    <div className="row">
                        {data.map(item =>
                            <div className="col-lg-6 d-block me-auto ms-auto">
                                <div className="tab-content d-flex align-items-center flex-column jusitfy-content-center m-4">
                                    <div className="video-container">
                                        {item.video}
                                    </div>
                                    <h2 className='pt-5'>{item.title}</h2>
                                    <p>
                                        {item.para}
                                    </p>
                                    <Button as={Link} to={'/Contact'} className='btn btn-red' >Contact Us <FontAwesomeIcon icon={faPhone} /> </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Media