// import React, { useState } from 'react';
// import './GalleryComp.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faClose, faLongArrowLeft, faLongArrowRight } from '@fortawesome/free-solid-svg-icons';

// const GalleryComp = ({ event }) => {
//   const [selectedImage, setSelectedImage] = useState(null);

//   const openDialog = (index) => {
//     setSelectedImage(index);
//   };

//   const closeDialog = () => {
//     setSelectedImage(null);
//   };

//   const handleNext = () => {
//     setSelectedImage((prevIndex) => (prevIndex + 1) % event.length);
//   };

//   const handlePrevious = () => {
//     setSelectedImage((prevIndex) => (prevIndex - 1 + event.length) % event.length);
//   };

//   return (
//     <>
//       <div className="imageGallery">
//         {event.map((image, index) => (
//           <button key={index} onClick={() => openDialog(index)} className='galleryBtn'>
//             <picture>
//               <img
//               className='galleryBtnImg'
//                 width="170px"
//                 height="auto"
//                 src={image} 
//                 alt={`Image ${index + 1}`}
//                 loading="lazy"
//               />
//             </picture>
//           </button>
//         ))}
//       </div>
//       {selectedImage !== null && (
//         <div className="modal">
//           <div className="modalContent">
//             <button className="prevBtn" onClick={handlePrevious}>
//               <FontAwesomeIcon icon={faLongArrowLeft} />
//             </button>
//             <img
//               className="zoomedImg"
//               src={event[selectedImage]} 
//               alt={`Image ${selectedImage + 1}`}
//               loading="lazy"
//             />
//             <button className="nextBtn" onClick={handleNext}>
//               <FontAwesomeIcon icon={faLongArrowRight} />
//             </button>
//             <button className="closeBtn" onClick={closeDialog}>
//               <FontAwesomeIcon icon={faClose} />
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default GalleryComp;
import React, { useState } from 'react';
import './GalleryComp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft, faLongArrowRight } from '@fortawesome/free-solid-svg-icons';

function GalleryComp({ event }) {
  const [selectedImage, setSelectedImage] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const thumbnailsPerPage = window.innerWidth < 768 ? 2 : 4;

  const handleThumbnailClick = (index) => {
    setSelectedImage(index);
  };

  const handleNext = () => {
    if (startIndex + thumbnailsPerPage < event.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  return (
    <div className="gallery-comp">
      <div className="gallery-main">
        <img
          width={'100%'}
          src={event[selectedImage]}
          alt={`Event ${selectedImage + 1}`}
          className="main-image"
        />
      </div>
      <div className="gallery-thumbnails d-flex justify-content-between align-items-center mt-3">
        <button className="slider-nav prevBtn" onClick={handlePrev}>
          <FontAwesomeIcon icon={faLongArrowLeft} />
        </button>
        <div className="thumbnail-container d-flex">
          {event.slice(startIndex, startIndex + thumbnailsPerPage).map((img, index) => (
            <div className="thumbnail-gallery-image" key={index + startIndex}>
              <img
                width={'120px'}
                key={index + startIndex}
                src={img}
                alt={`Event ${index + startIndex + 1}`}
                className={index + startIndex === selectedImage ? 'active-thumbnail' : ''}
                onClick={() => handleThumbnailClick(index + startIndex)}
              />
            </div>
          ))}
        </div>
        <button className="slider-nav nextBtn " onClick={handleNext}>
          <FontAwesomeIcon icon={faLongArrowRight} />
        </button>
      </div>
    </div>
  );
}

export default GalleryComp;

