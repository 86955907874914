import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './Responsive.css';

import '@fortawesome/fontawesome-svg-core/styles.css';
import NavBar from './Components/Navbar/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Home from '../src/Pages/Home';
import Export from './Pages/Export';
import Footer from './Components/Footer/Footer';
import History from './Pages/History';
import Founder from './Pages/Founder';
import Media from './Pages/Media';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
import Topbar from './Components/TopBar/Topbar';
import Household from './Pages/Products/Household';
import BabyCare from './Pages/Products/BabyCare';
import SelfCare from './Pages/Products/SelfCare';
import InsectKiller from './Pages/Products/InsectKiller';
import CoilRepllenet from './Pages/Products/CoilRepllenet';
import ProductDetail from './Pages/ProductDetail'; // Import ProductDetail component

function App() {
  return (
    <BrowserRouter>
      <Topbar />
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/History' element={<History />} />
        <Route path='/Founder-Message' element={<Founder />} />
        <Route path='/Products'>
          <Route path='Household-stuff' element={<Household />} />
          <Route path='Baby-Care' element={<BabyCare />} />
          <Route path='Coil-&-Repellent' element={<CoilRepllenet />} />
          <Route path='Self-Care' element={<SelfCare />} />
          <Route path='Insect-killer' element={<InsectKiller />} />

          <Route path=':productId' element={<ProductDetail />} />

        </Route>
        <Route path='/Gallery' element={<Gallery />} />
        <Route path='/Export' element={<Export />} />
        <Route path='/Media' element={<Media />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>
      <div className="footer-section">
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
