import React from 'react';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SoapImg from '../../Assets/products/babycare/soap.png';
import WipesImg from '../../Assets/products/babycare/wipes.png';
import ProductBg from '../../Assets/backgroundVideo/product-detail-bg.png';
import BabyCareBg from '../../Assets/banners/babycare.jpg'
import BabyCareMobileBg from '../../Assets/banners/babycare-mobile.jpg'
import Productbanners from '../../Components/ProductBanners/Productbanners';

function BabyCare() {
    const products = [
        {
            id: 'bebe-soap',
            name: 'BEBE SOAP',
            image: SoapImg,
            description: 'We understand the love a baby needs',
        },
        {
            id: 'bebe-wipes',
            name: 'BEBE WIPES',
            image: WipesImg,
            description: 'Clean and rash-free bum bum',
        },
    ];

    return (
        <>
            <Productbanners img={BabyCareBg} mobileImg={BabyCareMobileBg} />
            <div className="product-section">
                <div className="container">
                    <div className="row">
                    <div className="col-12 justify-content-center d-flex">
                            <div className="title">
                                Baby Care
                                <div className="underline">
                                <div className="style1"></div>
                                <div className="style2"></div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-12 text-center mb-5">
                            <div className="para">
                            Quality your baby deserves
                            </div>
                        </div>
                        {products.map(product => (
                            <div key={product.id} className="col-lg-3">
                                <div className="product-card">
                                    <div
                                        className="product-img"
                                        style={{
                                            backgroundImage: `url(${ProductBg})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center center',
                                        }}
                                    >
                                        <img src={product.image} alt={product.name} />
                                    </div>
                                    <div className="product-content">
                                        <h3>{product.name}</h3>
                                        <p>{product.description}</p>
                                    </div>
                                    <div className="product-btn">
                                        <Button as={Link} className="btn-red d-block ms-5 me-5" to={`/products/${product.id}`}>
                                            View Details
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default BabyCare;
