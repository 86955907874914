import React from 'react';
import './Slider.css';
import BgVideo from '../../Assets/backgroundVideo/background.mp4';
import { Button } from 'react-bootstrap';
// import { Button } from 'bootstrap';
import { Link } from 'react-router-dom';

function Slider() {
  return (
    <div className='bgVideo'>
      <div className="slider-content">
        <h1>COBRA HYGIENE (PVT) LTD.</h1>
        <h4>
          Established in 1988. <br />
          Let Cobra hygiene helps you to find the
          permanent solution and lead a hygienic life
        </h4>
        <div className="d-flex gap-3 mt-4">
          <Button as={Link} to={'/History'} className='btn btn-red slider-btn'> About us </Button>
          <Button as={Link} to={'/Contact'} className='btn btn-red slider-btn'> Contact us </Button>
        </div>
      </div>
      <video autoPlay loop muted playsInline className='video' width={"100%"}>
        <source src={BgVideo} type='video/mp4' />
      </video>
    </div>
  );
}

export default Slider;
