import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import { Button } from 'react-bootstrap';
import Anitleece from '../../Assets/products/selfcare/antileece.png';
import Shampoo from '../../Assets/products/selfcare/shampoo.png';
import HerbalSoap from '../../Assets/products/selfcare/neem.png';
import Powder from '../../Assets/products/selfcare/powder.png';
import Spray from '../../Assets/products/selfcare/spray.png';
import ProductBg from '../../Assets/backgroundVideo/product-detail-bg.png';
import SelfCareBg from  '../../Assets/banners/selfcare.jpg'
import SelfCareMobileBg from  '../../Assets/banners/selfcare-mobile.jpg'
import Productbanners from '../../Components/ProductBanners/Productbanners';

function SelfCare() {
    const products = [
        {
            id: 'sachet-antilice-shampoo',
            name: 'SACHET–ANTILICE SHAMPOO',
            image: Anitleece,
            description: 'Lather, Rinse, Repeat, Sparkle',
        },
        {
            id: 'cobra-antilice-shampoo',
            name: 'COBRA ANTILICE SHAMPOO',
            image: Shampoo,
            description: 'Lather, Rinse, Repeat, Sparkle',
        },
        {
            id: 'ku-ku-herbal-soap',
            name: 'KU KU HERBAL SOAP',
            image: HerbalSoap,
            description: 'Freshness ka ehsaas chore na saath',
        },
        {
            id: 'ku-ku-prickly-powder',
            name: 'KU KU PRICKLY POWDER',
            image: Powder,
            description: 'Keep your cool with you whole day',
        },
        {
            id: 'cobra-head-louse-and-nit-spray',
            name: 'COBRA HEAD LOUSE AND NIT SPRAY',
            image: Spray,
            description: 'Keep your hair irritation free',
        },
    ];

    return (
        <>
            <Productbanners img={SelfCareBg} mobileImg={SelfCareMobileBg} />
            <div className="product-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 justify-content-center d-flex">
                            <div className="title">
                                Self Care
                                <div className="underline">
                                <div className="style1"></div>
                                <div className="style2"></div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-12 text-center mb-5">
                            <div className="para">
                            You are the caretaker of yourself so choose wisely!!
                            </div>
                        </div>
                        {products.map(product => (
                            <div key={product.id} className="col-lg-3">
                                <div className="product-card">
                                    <div
                                        className="product-img"
                                        style={{
                                            backgroundImage: `url(${ProductBg})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center center',
                                        }}
                                    >
                                        <img src={product.image} alt={product.name} />
                                    </div>
                                    <div className="product-content">
                                        <h3>{product.name}</h3>
                                        <p>{product.description}</p>
                                    </div>
                                    <div className="product-btn">
                                        <Button as={Link} className='btn-red d-block ms-5 me-5' to={`/products/${product.id}`}>View Details</Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SelfCare;
