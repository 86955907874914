import { faBullseye, faEye, faHandshake } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './Statements.css'

function Statements() {

    const data = [
        {
            icon: faEye,
            title: "Vision",
            para: "Our vision is to make every house safe, clean and fragrant where every family will live comfortably."
        },
        {
            icon: faBullseye,
            title: "Mission",
            para: "Our mission is to provide an insect, germ-free environment at the most possible affordable prices."
        },
        {
            icon: faHandshake,
            title: "Core Values",
            para: "Our team is a family who works together to provide rapid action to ease the customer’s worries."
        }
    ]

    return (

        <div className='row'>
        {data.map((item, index) => (
            <div className="col-lg-4 mb-3" key={index}>
                <div className='box-content'>
                <FontAwesomeIcon icon={item.icon} size='3x' className='boxes-icons'/>
                <h2> <b> {item.title} </b> </h2>
                <p>{item.para}</p>
            </div>
            </div>
        ))}
    </div>
    )
}

export default Statements