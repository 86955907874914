import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import './TopBar.css'

function Topbar() {
    return (
        <div className='top-bar bg-dark p-2'>
            <div className="container topbar-container">
                <div className="contact text-white">
                    <FontAwesomeIcon icon={faPhone} className='topbar-icon' />
                    &nbsp; <a href="tel:+923162892230" className='topbar-detail'>+923162892230</a> &nbsp;
                    | &nbsp; <FontAwesomeIcon icon={faEnvelope} className='topbar-icon' />
                    &nbsp; <a href="mailto:info@cobrahygiene.com" className='topbar-detail'>info@cobrahygiene.com</a>
                </div>
                <div className="social-icons mt-0">
                    <Link target='_blank' to="https://www.facebook.com/cobrahygiene/">
                        <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link target='_blank' to="https://www.instagram.com/cobrahygiene/">
                        <i className="fab fa-instagram"></i>
                    </Link>
                    <Link target='_blank' to="https://www.pinterest.fr/cobrahygiene/">
                        <i className="fa-brands fa-pinterest pinterest-bg"></i>
                    </Link>
                    <Link target='_blank' to="https://www.tiktok.com/@cobra_hygiene/">
                        <i className="fab fa-tiktok tiktok-bg"></i>
                    </Link>
                    <Link target='_blank' to="https://www.youtube.com/channel/UCQhwYzdJp4IqF0MEE9YehMQ">
                        <i className="fab fa-youtube youtube-bg"></i>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Topbar