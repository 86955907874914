import React from 'react'
import './Productbanners.css'

function Productbanners(props) {
  return (
    <div className='custom-banner'>
        <div className="banners desktop"  style={{ backgroundImage: `url(${props.img})` }}>
        </div>
        <div className="banners mobile"  style={{ backgroundImage: `url(${props.mobileImg})` }}>
        </div>
    </div>
  )
}

export default Productbanners