import React from 'react'

function ReverseInfo(props) {
    return (
        <div className='row flex-row-reverse'>
            <div className="col-lg-7 d-flex align-items-center">
                <div className="story-content">
                    {(props.title &&
                        <h1 className="title">
                            <b>  {props.title} </b>
                        </h1>
                    )}
                    {(props.subtitle &&
                        <h3 className="subtitle">
                            {props.subtitle}
                        </h3>
                    )}
                    {(props.para1 &&
                        <p className='text-justify'> {props.para1} </p>
                    )}
                    {(props.para2 &&
                        <p className='text-justify'> {props.para2} </p>
                    )}
                    {(props.para3 &&
                        <p className='text-justify'> {props.para3} </p>
                    )}
                </div>
            </div>
            <div className="col-lg-5">
                {/* <div className="image-area " style={{ width: '100%', height: '300px' }}>
                    <img src={props.img} width={'100%'} height={'100%'} className='object-fit-cover' />
                </div> */}
                <div className="" >
                    <img src={props.img} width={'100%'} height={'100%'} />
                </div>
            </div>
        </div>
    )
}

export default ReverseInfo