import React from 'react'
import Info from '../Components/Info/Info'
import FounderImg from '../Assets/founderMsg.png'
import FounderImgBg from '../Assets/backgroundVideo/founder-msg.jpg'
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb'

function Founder() {
  return (
    <>
    <Breadcrumb pageName={"Founder Message"} img={FounderImgBg} />
      <div className="founder-section">
        <div className="container">
          <Info
            title={"Founder message"}
            para1={"Being the founder of Uneek Corporation allowed me a unique opportunity, it allowed me to dream, to create a vision for what I think a company should look like and how it should operate without any history, baggage or constraints.  I was able to establish the values that have personal meaning to me and set out upon that course. Although I didn’t know if the dream would come true, I believed it would.  I believed that with the right team, we could create an award winning company with a culture that keeps our people engaged and joyful. I also realised that achieving the vision to the fullest would be a journey that would never end, because Uneek Corporation is alive and will continue to develop."}
            para2={"I am very proud of the fact that we expand Uneek Corporation into different industries with the image of a big business in mind, therefore paying special attention to growing our infrastructure in parallel with our revenue. But to understand Uneek Corporation, you have to look beyond our growth, systems and contracts. You have to look at our people. Uneek Corporation is a company of leaders that puts people at the centre of winning solutions."}
            para3={"A company is in business to deliver service and/or products to customers. The beautiful opportunity is that by creating a workplace that encourages our people to be the best they can be, our customers are getting the best they can get. This is a total win-win for us all. None of Uneek Corporation’s success could have been possible without a leadership team that is characterised by brilliance, collaboration, commitment, and innovation."}
            para4={'“We will continue to grow, always keeping an eye on the people.”'}
            para5={'Best Regards,'}
            para6 ={'Shaikh Abdul Hameed (Late)'}
            img={FounderImg}

          />
        </div>
      </div>
    </>
  )
}

export default Founder