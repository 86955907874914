import React, { useRef } from 'react';
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FooterSubscribe.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FooterSubscribe() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_o3c0jhv', 'template_9dbs28l', form.current, {
                publicKey: 'bNGGo7QVZ3OJeRQcw'
            })
            .then(() => {
                toast.success('Subscription successful! 🎉');
                form.current.reset();
            })
            .catch((error) => {
                toast.error(`Subscription failed: ${error.message}`);
                console.error('Error sending email:', error);
            });
    };

    return (
        <>
            <div className="footer-widget-heading">
                <h3>Subscribe</h3>
            </div>
            <div className="footer-text mb-25">
                <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
            </div>
            <div className="subscribe-form">
                <form ref={form} onSubmit={sendEmail} className='p-0'>
                    <input
                        type="email"
                        placeholder="Email Address"
                        name='user_email'
                        required
                    />
                    <button type="submit">
                        <FontAwesomeIcon icon={faPaperPlane} className="fa-paper-plane" />
                    </button>
                </form>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={<CustomCloseButton />}
            />
        </>
    );
}

const CustomCloseButton = ({ closeToast }) => (
    <button className="custom-close-button" onClick={closeToast}>
        <FontAwesomeIcon icon={faTimes} style={{ padding: '0 10px' }} />
    </button>
);

export default FooterSubscribe;
