import React, { useRef } from 'react';
import './ContactForm.css';
import { Link } from 'react-router-dom';
import MultiSelect from './MultiSelect/MultiSelect';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactForm() {
    const form = useRef();

    const sendContactForm = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_o3c0jhv',
                'template_9dbs28l',
                form.current,
                {
                    publicKey: '9nESEQMdNGZNBHwbQ'
                }
            )
            .then(
                (result) => {
                    console.log('Email sent successfully:', result.text);
                    toast.success('Email sent successfully');
                    e.target.reset();
                },
                (error) => {
                    console.error('Error sending email:', error.text);
                    toast.error('Failed to send email. Please try again later.');
                    e.target.reset();
                }
            );
    };

    return (
        <>
            <ToastContainer />
            <span className="big-circle"></span>
            <img src="img/shape.png" className="square" alt="" />
            <div className="form">
                <div className="row">
                    <div className="col-lg-4">
                        <div class="contact-info">
                            <h3 class="titlee">WANT TO ASK ANYTHING?
                                SEND US A MAIL ANYTIME</h3>
                            <p class="text">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
                                dolorum adipisci recusandae praesentium dicta!
                            </p>

                            <div class="info">
                                <div class="information">
                                    <i class="fas fa-map-marker-alt me-3"></i> &nbsp; &nbsp;
                                    <p >41/1, Block-3 Opposite Asma Homes, N.K.C.H.S, Behind Dolmen Mall, Tariq Road, Karachi.</p>
                                </div>
                                <div class="information">
                                    <i class="fas fa-envelope"></i> &nbsp; &nbsp;
                                    <p >cobrahygiene@gmail.com</p>
                                </div>
                                <div class="information">
                                    <i class="fas fa-phone"></i>&nbsp;&nbsp;
                                    <p >+92 316 2892230</p>
                                </div>
                            </div>

                            <div class="social-media">
                                <p><b>Connect with us :</b></p>
                                <div class="social-icons">
                                    <Link target='_blank' to="https://www.facebook.com/cobrahygiene/">
                                        <i class="fab fa-facebook-f"></i>
                                    </Link>
                                    <Link target='_blank' to="https://www.instagram.com/cobrahygiene/">
                                        <i class="fab fa-instagram"></i>
                                    </Link>
                                    <Link target='_blank' to="https://www.pinterest.fr/cobrahygiene/">
                                        <i class="fa-brands fa-pinterest pinterest-bg"></i>
                                    </Link>
                                    <Link target='_blank' to="https://www.tiktok.com/@cobra_hygiene/">
                                        <i class="fab fa-tiktok tiktok-bg"></i>
                                    </Link>
                                    <Link target='_blank' to="https://www.youtube.com/channel/UCQhwYzdJp4IqF0MEE9YehMQ">
                                        <i class="fab fa-youtube youtube-bg"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="contact-form">
                            <span className="circle one"></span>
                            <span className="circle two"></span>
                            <form autoComplete="off" className="form-group" ref={form} onSubmit={sendContactForm}>
                                <h3 className="title text-white">Get In Touch</h3>
                                <div className="row">
                                    <div className="input-container col-lg-6">
                                        <input type="text" name="user_name" className="form-control input" placeholder="Name" required />
                                    </div>
                                    <div className="input-container col-lg-6">
                                        <input type="email" name="user_email" className="form-control input" placeholder="Email" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-container col-lg-6">
                                        <input type="tel" name="user_phone" className="form-control input" placeholder="Phone" required />
                                    </div>
                                    <div className="input-container col-lg-6">
                                        <input type="text" name="user_city" className="form-control input" placeholder="City name" required />
                                    </div>
                                </div>
                                <div className="input-container">
                                    <MultiSelect />
                                </div>
                                <div className="input-container textarea">
                                    <textarea name="message" className="form-control input" placeholder="Message" required></textarea>
                                </div>
                                <input type="submit" value="Send" className="contact-btn" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactForm;
