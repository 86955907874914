import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../Assets/logo.png';
import './NavBar.css';

function NavBar() {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [isHomeOpen, setIsHomeOpen] = useState(false);
    const [isProductsOpen, setIsProductsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setIsNavCollapsed(true);
    }, [location]);

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 992 ? setIsMobile(true) : setIsMobile(false);
        };

        setResponsiveness();

        window.addEventListener('resize', setResponsiveness);

        return () => {
            window.removeEventListener('resize', setResponsiveness);
        };
    }, []);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const handleHomeToggle = (e) => {
        e.preventDefault();
        if (isMobile) {
            setIsHomeOpen(!isHomeOpen);
            setIsProductsOpen(false); 
        }
    };

    const handleProductsToggle = (e) => {
        e.preventDefault(); 
        if (isMobile) {
            setIsProductsOpen(!isProductsOpen);
            setIsHomeOpen(false); 
        }
    };

    const handleHoverOut = () => {
        if (!isMobile) {
            setIsHomeOpen(false);
            setIsProductsOpen(false);
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-body-tertiary sticky">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src={Logo} alt="Logo" width={150} />
                </Link>
                <button
                     className="navbar-toggler"
                     type="button"
                     data-bs-toggle="collapse"
                     data-bs-target="#navbarNav"
                     aria-controls="navbarNav"
                     aria-expanded={!isNavCollapsed ? true : false}
                     aria-label="Toggle navigation"
                     onClick={handleNavCollapse}
                 >
                     <span className="navbar-toggler-icon"></span>
                 </button>
                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item dropdown"
                            onMouseEnter={!isMobile ? () => setIsHomeOpen(true) : null}
                            onMouseLeave={!isMobile ? () => setIsHomeOpen(false) : null}>
                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown1" role="button" aria-expanded="false" onClick={handleHomeToggle}>
                               Cobra
                            </Link>
                            <div className={`dropdown-menu ${isHomeOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown1">
                                <Link className="dropdown-item" to="/">About Us</Link>
                                <Link className="dropdown-item" to="/History">History</Link>
                                <Link className="dropdown-item" to="/Founder-Message">Founder Message</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown"
                            onMouseEnter={!isMobile ? () => setIsProductsOpen(true) : null}
                            onMouseLeave={!isMobile ? () => setIsProductsOpen(false) : null}>
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" aria-expanded="false" onClick={handleProductsToggle}>
                                Products
                            </a>
                            <div className={`dropdown-menu ${isProductsOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown2">
                                <Link className="dropdown-item" to="/Products/Household-stuff">Household Stuff</Link>
                                <Link className="dropdown-item" to="/Products/Baby-Care">Baby Care</Link>
                                <Link className="dropdown-item" to="/Products/Coil-&-Repellent">Coil and Repellent</Link>
                                <Link className="dropdown-item" to="/Products/Self-Care">Self Care</Link>
                                <Link className="dropdown-item" to="/Products/Insect-killer">Insect Killers</Link>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Gallery">Gallery</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Media">Media</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Export">Export</Link>
                        </li>
                        <li>
                            <Link className="btn btn-red text-white ms-sm-0" to="/Contact">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
