import React from 'react'
import './Info.css'

function Info(props) {
    return (
        <div className='row'>
            <div className="col-lg-7">
                <div className="story-content">
                    {(props.title &&
                        <h1 className="title">
                            <b>  {props.title} </b>
                        </h1>
                    )}
                    {(props.subtitle &&
                        <h3 className="subtitle">
                            {props.subtitle}
                        </h3>
                    )}
                    {props.para1 && (
                        <p className='text-justify'> {props.para1} </p>
                    )}
                    {(props.para2 &&
                        <p className='text-justify'> {props.para2} </p>
                    )}
                    {(props.para3 &&
                        <p className='text-justify'> {props.para3} </p>
                    )}
                    {(props.para4 &&
                        <p className='text-justify'> {props.para4} </p>
                    )}
                    {(props.para5 && props.para6 &&
                        <p className='text-justify'> {props.para5} <br /> {props.para6} </p>
                    )}
                </div>
            </div>
            <div className="col-lg-5">  {/* style={{ backgroundImage: `url(${props.img})`, backgroundSize: "cover", backgroundPosition: "Center Center" }}> */}
                <div className="" >
                    <img src={props.img} width={'100%'} height={'100%'} />
                </div>
            </div>
        </div>
    )
}

export default Info