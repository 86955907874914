import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Reviews.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser,  faLongArrowLeft, faLongArrowRight } from '@fortawesome/free-solid-svg-icons';


const Reviews = ({ testimonials }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Change this to 1 for mobile devices
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <FontAwesomeIcon icon={faLongArrowLeft} className='slider-btns prev'/>,
    nextArrow: <FontAwesomeIcon icon={faLongArrowRight} className='slider-btns next'/>,
    dots: false,
    responsive: [
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
        }
      }
    ]

  };

  return (
    <Slider {...settings}>
      {testimonials.map((testimonial, index) => (
        <div key={index} className="testimonial-slide">
          <div className="testimonial-content">
            <FontAwesomeIcon icon={faCircleUser} className='icon' />
            <p>{testimonial.text}</p>
            <div className="testimonial-rating">
              {[...Array(testimonial.rating)].map((_, i) => (
                <span key={i} className="star">&#9733;</span>
              ))}
            </div>
            <p>- {testimonial.author}</p>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Reviews;
