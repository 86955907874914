import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ToiletBowl from '../../Assets/products/household/toilet-bowl.PNG';
import Polish from '../../Assets/products/household/polish.PNG';
import BedBug from '../../Assets/products/household/bed-bug.png';
import GlueImg from '../../Assets/products/household/Glue.png';
import ProductBg from '../../Assets/backgroundVideo/product-detail-bg.png'
import HouseholdBg from '../../Assets/banners/household.jpg'
import HouseholdMobileBg from '../../Assets/banners/household-mobile.jpg'
import Productbanners from '../../Components/ProductBanners/Productbanners';

function Household() {
    const products = [
        {
            id: 'toilet-bowl-cleaner',
            name: "TOILET BOWL CLEANER",
            image: ToiletBowl,
            description: "Yellow toilet bowl solution"
        },
        {
            id: 'cobra-xtreme-bedbug-killer',
            name: "COBRA XTREME BED BUG KILLER",
            image: BedBug,
            description: "Say goodbye to bed bugs"
        },
        {
            id: 'cobra-extreme-glue',
            name: "COBRA EXTREME GLUE",
            image: GlueImg,
            description: "Sticks sticky keeps the furniture sturdy"
        },
        {
            id: 'shinax-extra-furniture-polish',
            name: "SHINAX EXTRA FURNITURE POLISH",
            image: Polish,
            description: "Din -Ba- Din Naya"
        }
    ];

    return (
        <>
            <Productbanners img={HouseholdBg} mobileImg={HouseholdMobileBg} />
            <div className="product-section">
                <div className="container">
                    <div className='row'>
                        <div className="col-12 justify-content-center d-flex">
                            <div className="title">
                                House Hold Stuff
                                <div className="underline">
                                    <div className="style1"></div>
                                    <div className="style2"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 text-center mb-5">
                            <div className="para">
                                Find all the stuff for your day to day usage
                            </div>
                        </div>
                        {products.map(product => (
                            <div key={product.id} className="col-lg-3">
                                <div className="product-card">
                                    <div className="product-img" style={{ backgroundImage: `url(${ProductBg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center center', backgroundColor: '#e6e6e6', borderRadius: '40px'}}>
                                        <img src={product.image} alt={product.name} />
                                    </div>
                                    <div className="product-content">
                                        <h3>{product.name}</h3>
                                        <p>{product.description}</p>
                                    </div>
                                    <div className="product-btn">
                                        <Button as={Link} className='btn-red d-block ms-5 me-5' to={`/products/${product.id}`}>View Details</Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Household;
