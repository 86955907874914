import React, { useState } from 'react';
import './Categories.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import BabyCare from '../../Assets/categories/baby-care.jpg'
import InsectKiller from '../../Assets/categories/insect-killer.jpg'
import Coil from '../../Assets/categories/Coil-&-repellant.jpg'
import SelfCare from '../../Assets/categories/slef-care.jpg'
import Household from '../../Assets/categories/house-hold.jpg'
import { Link } from 'react-router-dom';

function Categories() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = [
        {
            name: 'Baby Care',
            description: 'Quality your baby deserves',
            image: BabyCare,
            link: '/Products/Baby-Care' 
        },
        {
            name: 'Insect Killer',
            description: 'Get rid of these creepy creatures In the best hygienic way possible',
            image: InsectKiller,
            link: '/Products/Insect-killer'
        },
        {
            name: 'Coil & Repellent',
            description: 'Kill the beast which keeps you awake at night',
            image: Coil,
            link: '/Products/Coil-&-Repellent'
        },
        {
            name: 'Self Care',
            description: 'You are the caretaker of yourself so choose wisely!!',
            image: SelfCare,
            link: '/Products/Self-Care'
        },
        {
            name: 'Household Stuff',
            description: 'Find all the stuff for your day to day usage',
            image: Household,
            link: '/Products/Household-stuff'
        },
    ];
    

    const nextSlide = () => {
    let items = document.querySelectorAll('.item');
    document.querySelector('.slide').appendChild(items[0]);
};

const prevSlide = () => {
    let items = document.querySelectorAll('.item');
    document.querySelector('.slide').prepend(items[items.length - 1]);
};

    return (
        <div className="categories-container">
            <div className="slide">
                {slides.map((slide, index) => (
                    <div
                        className={`item ${index === currentIndex ? 'active' : ''}`}
                        key={index}
                        style={{ backgroundImage: `url(${slide.image})` }}
                    >
                        <div className="content">
                            <h3 className="name title">{slide.name}</h3>
                            <p className="des para">{slide.description}</p>
                            <Link to={slide.link} className='btn btn-red text-white'>See More</Link>
                        </div>
                    </div>
                ))}
            </div>

            <div className="button">
                <button className="prev" onClick={prevSlide}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button className="next" onClick={nextSlide}>
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </div>
        </div>
    );
}

export default Categories;