import React from 'react'
import StartUp from '../Assets/startup.jpg'
import Info from '../Components/Info/Info'
import ReverseInfo from '../Components/ReverseInfo/ReverseInfo'
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb'
import HistoryBg from '../Assets/backgroundVideo/history.jpg'
import HistoryImg from '../Assets/hisotry.jpg'


function History() {
    return (
        <>
        <Breadcrumb pageName="History" img={HistoryBg} />
            <div className='start-section'>
                <div className="container">
                    <Info
                        title={"HOW IT STARTED"}
                        para1={"This journey started in 1988 by the most sturdy, strong and genius man who was very determined to establish and grow this business all over Pakistan and make people’s lives easy and that person is none other than Mr. Shaikh Abdul Hameed (Late)."}
                        para2={"He was very positive that Uneek Corporation could be a big hit and he was not wrong at all, later it emerged in the Cobra which is a leading brand up till now. He set the trend when nobody was even aware that these products can become one of the necessities of day to day life, he brought the change but it did not happen overnight, he struggled a lot just to make cobra what it is today. His sons played a vital role in this journey and made father’s success become true by being unshaken pillars."}
                        img={StartUp}
                    />
                </div>
            </div>
            <div className="history-section">
                <div className="container">
                    <ReverseInfo
                        title={"HISTORY"}
                        para1={"Cobra Hygiene (PVT) Ltd., formerly known as Uneek Corporation, was established in 1988. The foundation was laid by Chief Executive Mr. Shaikh Abdul Hameed (Late), who had a vast experience over a period of 40 years. Uneek’s operation supported by the four foundations who are actually the sons of S.A Hameed (Late) has fully grown up the company Cobra Hygiene PVT Ltd, into a family business concern while they joined the company after their education simultaneously and gradually over a period of 15 years."}

                        img={HistoryImg}
                    />
                </div>
            </div>
        </>
    )
}

export default History