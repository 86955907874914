import React from 'react';
import Reviews from './Carousel/Reviews';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

function Testimonials() {
    const testimonials = [
        {
            text: "As a father of three kids, I was very worried for my kids because they used to get sick every now and then. Since I started using Cobra Hygiene’s insect killer, all the insects are out of my house and my kids are living a healthy life again.",
            author: "Ali Arsh",
            rating: 5, 
            img: faCircleUser 
        },
        {
            text: "I am very much satisfied after using head louse and nit spray. It really helped to keep my scalp clean and gave me incredible confidence. This is the only product that finally got rid of me from lice!",
            author: "Samina Akram",
            rating: 4, 
            img: faCircleUser 
        },
        {
            text: "My 2 year old daughter has very sensitive skin. I searched through the whole market for wipes but could not find the best one which could suit her skin until I found Bebe wipes, which are the most soft and organic wipes.",
            author: "Kanwal Khan",
            rating: 5, 
            img: faCircleUser 
        }
    ];

    return (
        <div className="app">
            <Reviews testimonials={testimonials} />
        </div>
    );
}

export default Testimonials;


