// HouseHold 
import ToiletBowl from '../Assets/products/household/toilet-bowl.PNG';
import ToiletBowl2 from '../Assets/products/household/toilet-bowl-2.PNG';
import BedBug from '../Assets/products/household/bed-bug.png';
import GlueImg from '../Assets/products/household/Glue.png';
import Polish from '../Assets/products/household/polish.PNG';
import Polish2 from '../Assets/products/household/polish2.PNG';

// Baby Care 
import SoapImg from '../Assets/products/babycare/soap.png'
import SoapImg2 from '../Assets/products/babycare/soap2.png'
import SoapImg3 from '../Assets/products/babycare/soap3.png'
import WipesImg from '../Assets/products/babycare/wipes.png'
import WipesImg2 from '../Assets/products/babycare/wipes2.png'

// Coil & Repellent 
import Coil from '../Assets/products/coil/coils.png'
import Coil2 from '../Assets/products/coil/coils2.png'
import Coil3 from '../Assets/products/coil/coils3.png'
import Coil4 from '../Assets/products/coil/coils4.png'
import AllinOne from '../Assets/products/coil/3in1.png'
import AllinOne2 from '../Assets/products/coil/3in1-green.png'
import AllinOne3 from '../Assets/products/coil/3in1-yellow.png'
import Refill from '../Assets/products/coil/electric.png'
import Refill2 from '../Assets/products/coil/refill2.PNG'
import Refill3 from '../Assets/products/coil/refill3.png'
import Sachet from '../Assets/products/coil/sachet.png'
import Sachet2 from '../Assets/products/coil/sachet2.png'
import Sachet3 from '../Assets/products/coil/sachet3.png'
import Sachet4 from '../Assets/products/coil/sachet4.png'
import Lotion from '../Assets/products/coil/lotion.png'
import Lotion2 from '../Assets/products/coil/lotion2.png'
import Lotion3 from '../Assets/products/coil/lotion3.png'
import Lotion4 from '../Assets/products/coil/lotion4.png'
import Lotion5 from '../Assets/products/coil/lotion5.png'
import Lotion6 from '../Assets/products/coil/lotion6.png'
import Lotion7 from '../Assets/products/coil/lotion7.png'
import Lotion8 from '../Assets/products/coil/lotion8.png'
import MatMachine from '../Assets/products/coil/mat-machine.png'

// /Self Care
import Anitleece from '../Assets/products/selfcare/antileece.png';
import Anitleece2 from '../Assets/products/selfcare/antileece2.png';
import Anitleece3 from '../Assets/products/selfcare/antileece3.png';
import Anitleece4 from '../Assets/products/selfcare/antileece4.png';
import Anitleece5 from '../Assets/products/selfcare/antileece5.png';
import Shampoo from '../Assets/products/selfcare/shampoo.png';
import HerbalSoap from '../Assets/products/selfcare/neem.png';
import HerbalSoap2 from '../Assets/products/selfcare/charcoal.PNG';
import HerbalSoap3 from '../Assets/products/selfcare/blackseed.PNG';
import Powder from '../Assets/products/selfcare/powder.png';
import Powder2 from '../Assets/products/selfcare/powder2.png';
import Spray from '../Assets/products/selfcare/spray.png';

// Insect Killer
import Aerosal from '../Assets/products/InsectKiller/aerosel-insect-killer-300.png';
import Aerosal2 from '../Assets/products/InsectKiller/aerosel-insect-killer.png';
import Aerosal3 from '../Assets/products/InsectKiller/aerosel-insect-killer-500.png';
import Aerosal4 from '../Assets/products/InsectKiller/aerosel-insect-killer-600.png';
import Aerosal5 from '../Assets/products/InsectKiller/aerosel-insect-killer-750.png';
import ExtraStrongAerosal from '../Assets/products/InsectKiller/extra-strong-aerosel.png';
import KillerPowder from '../Assets/products/InsectKiller/powder.png';
import Bomb from '../Assets/products/InsectKiller/3in1.png';
import Insecticide from '../Assets/products/InsectKiller/insecticide.png';
import Insecticide2 from '../Assets/products/InsectKiller/insecticide2.png';
import Insecticide3 from '../Assets/products/InsectKiller/insecticide3.png';
import Insecticide4 from '../Assets/products/InsectKiller/insecticide4.png';
import Insecticide5 from '../Assets/products/InsectKiller/insecticide5.png';
import Insecticide6 from '../Assets/products/InsectKiller/insecticide6.png';
import Lizard from '../Assets/products/InsectKiller/lizard.png';


const mockProducts = [
    // House Hold 
    {
        id: 'toilet-bowl-cleaner',
        name: 'Toilet Bowl Cleaner',
        tagline: 'Horrendous yellow toilet bowl with damaged reputation? Do not worry we’ve got your back!!!!',
        description: 'Cobra Toilet Bowl Cleaner cleans and kills the bathroom germs with active chemical formula which makes the bathroom squeaky clean, available in 2 variants baking soda & lime.',
        image: ToiletBowl,
        images: [ToiletBowl, ToiletBowl2],
        type1: 'Baking Soda',
        type2: 'Lime (Black)',
        table: {
            size: '500ml',
            cartonPack: '24 Pieces Carton'
        },
        sku: '1004-A',
        category: 'Household-stuff',
        use: {
            point1: 'Use your toilet brush to scrub away stains and deposits.',
            point2: 'Scrub well, with the cleaner, around the inside of your bowl',
            point3: ' Let everything sit for five minutes, then flush it away'
        },
        precaution: {
            point1: 'Avoid contact with skin, eyes and clothing.',
            point2: 'Wash hands thoroughly after handling.',
        }
    },

    {
        id: 'cobra-xtreme-bedbug-killer',
        name: 'Cobra Xtreme Bed Bug Killer',
        tagline: 'Say goodbye to bed bugs',
        description: 'Cobra Xtreme Bed Bug Killer kills even those insects which are hidden behind the baseboards, cracks and cervix of the bed, keeps the bed bugs away for up to 9 months.',
        image: BedBug,
        type1: 'Purple',
        table: {
            size: '300ml',
            cartonPack: '48 Pieces Carton'
        },
        sku: '1001-B',
        category: 'Household-stuff',
        use: {
            point1: 'Spray generously around all the corners of the bed.',
            point2: 'Use for the cracks of the bed.',
            point3: 'For indoor use only.'
        },
        precaution: {
            point1: 'Keep out of reach of children.',
            point2: 'Spray 15 minutes before laying on the bed.',
            point3: 'For cracks and headboards.'
        }
    },
    {
        id: 'cobra-extreme-glue',
        name: 'Cobra Extreme Glue',
        tagline: 'Sticks sticky keeps the furniture sturdy',
        description: 'Cobra Extreme Glue binds almost anything instantly and bind it together permanently. It sets in seconds, holding power is up to 2 tons.',
        image: GlueImg,
        type1: 'Regular',
        table: {
            size: '1.5gm',
            cartonPack: '990 Pieces Carton'

        },
        sku: '1004-B',
        category: 'Household-stuff',
        use: {
            point1: 'Clean off the surface first.',
            point2: 'Apply a thin layer.',
            point3: 'Press it for about 30 seconds.',
            point4: 'Dry the glued area with the help of a fan or let it air dry.'
        },
        precaution: {
            point1: 'Avoid contact with skin and eyes.',
            point2: 'Wash hands and any other contaminated areas of the body with soap and water before leaving the work site.',
            point3: 'Avoid eating, drinking, and smoking when using the product.'
        }
    },
    {
        id: 'shinax-extra-furniture-polish',
        name: "SHINAX EXTRA FURNITURE POLISH",
        tagline: 'Din -Ba- Din Naya',
        description: "Shinax Furniture Polish is long lasting which keeps the furniture new for a long time. Available in 2 variants orange & yellow.",
        image: Polish,
        images: [ Polish, Polish2 ],
        type1: 'Orange',
        type2: 'Yellow',
        table: {
            size: '300ml',
            cartonPack: '48 Pieces Carton'

        },
        sku: '1004-C',
        category: 'Household-stuff',
        use: {
            point1: 'Use at room temperature.',
            point2: 'Shake before use.',
            point3: 'Spray the polish in a wide sweeping motion to a clean, dry surface.',
            point4: 'Then rub it off with a dry cloth.'
        },
        precaution: {
            point1: 'Avoid spraying it around inflammable substances.',
            point2: 'There may be mild irritation at the site of contact, so try not to spray it around the skin.'
        }
    },

    // Baby Care 
    {
        id: 'bebe-wipes',
        name: "Bebe Wipes",
        tagline: 'Soft and gentle, perfect for your little gentle',
        tagline2: 'Clean & rash free bum bum',
        description: "Cobra Baby Cloth Wipes are alchohol-free, organic wet wipes which protects the sensitive and soft skin of the baby",
        image: WipesImg,
        images: [WipesImg, WipesImg2],
        type1: 'Pink',
        type2: 'Green',
        table: {
            size: 'Regular',
            cartonPack: '24 Pieces Carton'

        },
        sku: '1006-A',
        category: 'Baby-Care',
        use: {
            point1: 'Use wipes to clean baby’s bottom in cold weather.',
            point2: 'Throw it after one use.',
            point3: 'Do not need to use the cream after cleaning with Bebe Wipes! It keeps the baby’s body moisturized.'
        },
        precaution: {
            point1: 'If the baby has any allergies, consider a patch test before use.',
            point2: 'Ensure there is no adverse reaction.'
        }
    },
    {
        id: 'bebe-soap',
        name: "BEBE SOAP",
        tagline: 'We understands the love a baby needs',
        tagline2: 'The bath your baby won’t refuse',
        tagline3: 'Buttery skin for your softies',
        description: "Soft & Suitable for baby’s skin. Available in 2 Variants Almond Oil Soap, Lotion Formula Soap in three soft colors white, pink & blue.",
        image: SoapImg,
        images: [SoapImg, SoapImg2, SoapImg3],
        type1: 'Pink',
        type2: 'White',
        type3: 'Blue',
        table: {
            size: 'Regular',
            cartonPack: '60 Pieces Carton'

        },
        sku: '1006-B',
        category: 'Baby-Care',
        use: {
            point1: 'Lather the soap on hands.',
            point2: 'Gently rub it on baby’s skin.',
            point3: 'Rinse off then dry'
        },
        precaution: {
            point1: 'For external use only.',
            point2: 'Use strictly under adult supervision.'
        }
    },


    //Coil & Repellent 
    {
        id: 'cobra-coil-10s-jumbo',
        name: "COBRA COIL 10’S JUMBO",
        tagline: 'Khushboo se machhar huye zakhmi',
        description: "Cobra Coil Jumbo protects the entire house overnight with just one coil, available in 4 fragrances: Bakhoor, Lavender, Rose, & Jasmine",
        image: Coil,
        images: [Coil, Coil2, Coil3, Coil4],
        type1: 'Rose ',
        type2: 'Jasmine',
        type3: 'Lemon',
        type4: 'Lavender',
        table: {
            size: 'Regular',
            cartonPack: '60 Pieces Carton'

        },
        sku: '1005-D',
        category: 'Coil-&-Repellent',
        use:
        {
            point1: 'Lit the coil, then blow it off.',
            point2: 'Place it in one corner of your room.',
            point3: 'For indoor use only.'
        },
        precaution: {
            point1: 'Keep out of reach of children.',
            point2: 'Blow the coil quickly after lighting it up.',
            point3: 'Keep away the clothes, curtain, quilt or such things.'
        }
    },
    {
        id: 'cobra-liquid-destroyer-3-in-1-combo-machine',
        name: "COBRA LIQUID DESTROYER 3 IN 1 COMBO",
        tagline: 'Machharon ki tabahi he tabahi',
        description: "Cobra Liquid Destroyer is a vaporizer that repels all flying insects. Available in 3 variants: Rose, Jasmine, & Lemon.",
        image: AllinOne,
        images: [AllinOne, AllinOne2, AllinOne3],
        type1: 'Rose ',
        type2: 'Jasmine',
        type3: 'Lemon',
        table: {
            size: 'Regular',
            cartonPack: '120 Pieces Carton'

        },
        sku: '1005-C',
        category: 'Coil-&-Repellent',
        use:
        {
            point1: 'Use as a vaporizer by inserting the fragrant refill.',
            point2: 'Use it as a mat machine by inserting the mat into the provided space.',
            point3: 'Built-in night bulb.',
            point4: 'Change the refill once a week.'
        },
        precaution: {
            point1: 'Read the instructions carefully provided on the box.',
        }
    },
    {
        id: 'cobra-plug-in-refill',
        name: "COBRA PLUG IN REFILL",
        tagline: 'Stopped? Why? Ran out of repellent? Here, change the refill and restart!!',
        description: "Available in 3 Fragrances: Rose, Jasmine, Lemon. Kills mosquitoes and provides overnight protection.",
        image: Refill,
        images: [Refill, Refill2, Refill3],
        type1: 'Rose ',
        type2: 'Jasmine',
        type3: 'Lemon',
        table: {
            size: '45ml',
            cartonPack: '120 Pieces Carton'

        },
        sku: '1005-B',
        category: 'Coil-&-Repellent',
        use:
        {
            point1: 'Insert the mat.',
            point2: 'Plug into the switch.',
        },
        precaution: {
            point1: 'Do not lit the mat.',
            point2: 'Keep out of reach of children.',
        }
    },
    {
        id: 'cobra-mosquito-repllent-sachet',
        name: "COBRA MOSQUITO REPLLENT SACHET",
        tagline: 'Repel & relax – Goodbye mosquitoes!',
        description: "Cobra Mosquito Repellent Sachet is available in 4 Variants: Regular, Lavender, Lemon, Jasmine. It protects the body from mosquito bites.",
        image: Sachet,
        images: [Sachet, Sachet2, Sachet3, Sachet4],
        type1: 'Regular ',
        type2: 'Jasmine',
        type3: 'Lemon',
        type4: 'Lavender',
        table: {
            size: '7ml',
            cartonPack: '1782 Pieces Carton'

        },
        sku: '1005-A',
        category: 'Coil-&-Repellent',
        use:
        {
            point1: 'Apply a generous amount on your skin.',
            point2: 'Apply every 4 hours.',
            point3: 'Preferably for outdoor use.',
        },
        precaution: {
            point1: 'If you have any allergies, consider a patch test before use.',
            point2: 'Ensure there is no adverse reaction.',
            point3: 'If any irritation or rash occurs, consult a health professional.',
            point4: 'Only for those aged 6 years or above.',
            point5: 'Keep away from heat and sunlight.'
        }
    },
    {
        id: 'cobra-mosquito-repllent-lotion',
        name: "COBRA MOSQUITO REPLLENT LOTION",
        tagline: 'Repel & relax – Goodbye mosquitoes!',
        description: "Cobra Mosquito Repellent Lotion is available in 4 variants: Regular, Lavender, Lemon, Jasmine. It protects the body from mosquito bites",
        image: Lotion,
        images: [Lotion, Lotion2, Lotion3, Lotion4, Lotion5, Lotion6, Lotion7, Lotion8],
        type1: 'Regular ',
        type2: 'Jasmine',
        type3: 'Lemon',
        type4: 'Lavender',
        table: {
            size: '20ml',
            cartonPack: '432 Pieces Carton',
            size2: '60ml',
            cartonPack2: '144'
        },
        sku: '1005-A',
        category: 'Coil-&-Repellent',
        use:
        {
            point1: 'Apply a generous amount on your skin.',
            point2: 'Apply every 4 hours.',
            point3: 'Preferably for outdoor use.'
        },
        precaution: {
            point1: 'If you have any allergies, consider a patch test before use.',
            point2: 'Ensure there is no adverse reaction.',
            point3: 'If any irritation or rash occurs, consult a health professional.',
            point4: 'Only for those aged 6 years or above.',
            point5: 'Keep away from heat & sunlight.'
        }
    },
    {
        id: 'cobra-mat-machine',
        name: "COBRA MAT MACHINE",
        tagline: 'An electrifying killer',
        description: "A powerful tool for eliminating annoying mosquitoes. This device is perfect for keeping your home free from airborne pests and ensuring your family and pets are safe.",
        image: MatMachine,
        type1: 'Regular ',
        table: {
            size: 'Regular',
            cartonPack: '20 Pieces Carton',
        },
        sku: '1007-A',
        category: 'Coil-&-Repellent',
        use:
        {
            point1: 'Insert the mat machine.',
            point2: 'Plug into the switch.'
        },
        precaution: {
            point1: 'Do not light the mat.',
            point2: 'Keep out of reach of children.'
        }
    },

    //Self Care
    {
        id: 'sachet-antilice-shampoo',
        name: "COBRA ANTILICE SHAMPOO – SACHET",
        tagline: 'Lather, Rinse, Repeat, Sparkle',
        description: "Cobra Anti lice Shampoo (Sachet) provides clean, soft hair. Advanced & Integrated formula to protect and strengthen the hair. Removes lice & nits in just one wash.",
        image: Anitleece,
        images: [Anitleece, Anitleece2, Anitleece3, Anitleece4, Anitleece5, Anitleece],
        type1: 'Apple / Red ',
        type2: 'Coconut / Blue ',
        type3: 'Cucumber / Green ',
        type4: 'Peach / Orange ',
        type5: 'Honey / Yellow ',
        table: {
            size: '6ml',
            cartonPack: '1728 Pieces Carton',
        },
        sku: '1003-B',
        category: 'Self-Care',
        use:
        {
            point1: 'Completely wet the hair.',
            point2: 'Lather the shampoo and spread it evenly from scalp to roots.',
            point3: 'Massage the scalp for about 40 seconds.',
            point4: 'Leave it for about 5-7 minutes.',
            point5: 'Rinse off nicely and dry the hair.'
        },
        precaution: {
            point1: 'Keep out of your mouth, nose, and eyes (may burn).',
            point2: 'Cover eyes and face with a towel.',
            point3: 'Keep eyes closed tightly.'
        }
    },
    {
        id: 'cobra-antilice-shampoo',
        name: "COBRA ANTILICE SHAMPOO",
        tagline: 'Lather, Rinse, Repeat, Sparkle',
        description: "Cobra Anti lice Shampoo provides clean, soft hair. Advanced & Integrated formula to protect and strengthen the hair. Removes lice & nits in just one wash.",
        image: Shampoo,
        type1: 'Regular',
        table: {
            size: '150ml',
            cartonPack: '54 Pieces Carton',
        },
        sku: '1003-B',
        category: 'Self-Care',
        use:
        {
            point1: 'Completely wet the hair.',
            point2: 'Lather the shampoo and spread it evenly from scalp to roots.',
            point3: 'Massage the scalp for about 40 seconds.',
            point4: 'Leave it for about 5-7 minutes.',
            point5: 'Rinse off nicely and dry the hair.'
        },
        precaution: {
            point1: 'Keep out of your mouth, nose, and eyes (may burn).',
            point2: 'Cover eyes and face with a towel.',
            point3: 'Keep eyes closed tightly.'
        }
    },
    {
        id: 'ku-ku-herbal-soap',
        name: "KU KU HERBAL SOAP",
        tagline: 'Freshness ka ehsaas chore na saath',
        description: "Available in 3 Variants: Charcoal, Neem, Black Seed. In-built Loofah for a fresh, clean, and soft body. Soap with dual benefits for cleansing and massage.",
        image: HerbalSoap,
        images: [HerbalSoap, HerbalSoap2, HerbalSoap3],
        type1: 'Neem',
        type2: 'Black Seed',
        type3: 'Charcoal',
        table: {
            size: '80ml',
            cartonPack: '72 Pieces Carton',
        },
        sku: '1003-A',
        category: 'Self-Care',
        use:
        {
            point1: 'Use the side with massage balls to gently massage in circular motions on wet skin.',
            point2: 'Use the smooth, rounded side of the soap on wet skin, on areas where you need extra exfoliation.',
            point3: 'Rinse away the foamy lather.'

        },
        precaution: {
            point1: 'Keep out of reach of children.',
            point2: 'For external use only.',
            point3: 'Avoid contact with eyes.',
            point4: 'If irritation occurs, discontinue use.'
        }
    },
    {
        id: 'ku-ku-prickly-powder',
        name: "KU KU PRICKLY POWDER",
        tagline: 'Keep your cool with you whole day',
        description: "Ku ku Prickly Powder alleviates inflammation and offers quick relief by easing the itchy sensation. It also reduces the redness of the rash. Keeps you cool and comfortable even when the weather is humid.",
        image: Powder,
        images: [ Powder, Powder2],
        type1: 'Neem',
        type2: 'Regular',
        table: {
            size: '230ml',
            cartonPack: '48 Pieces Carton',
        },
        sku: '1003-A',
        category: 'Self-Care',
        use:
        {
            point1: 'Dust the powder on body areas prone to sweating such as feet, legs, arms, and the whole body.',
            point2: 'Use as instructed.'
        },
        precaution: {
            point1: 'Not recommended for children below 3 years old.',
            point2: 'Avoid direct inhalation and usage on the face and private areas.'
        }
    },
    {
        id: 'cobra-head-louse-and-nit-spray',
        name: "COBRA HEAD LOUSE AND NIT SPRAY",
        tagline: 'Keep your hair irritation-free without any lengthy process.',
        description: "Cobra Head Louse & Nit Spray is easy to use. Odourless & uncoloured. Insecticide-free. Removes lice & nits with a protected formula for healthy hair.",
        image: Spray,
        type2: 'Regular',
        table: {
            size: '100ml',
            cartonPack: '50 Pieces Carton',
        },
        sku: '1003-B',
        category: 'Self-Care',
        use:
        {
            point1: 'Spray a sufficient quantity evenly onto dry hair.',
            point2: 'Be sure to cover all the hair and scalp.',
            point3: 'Massage well from the roots to tips, paying particular attention to the neck and the area behind the ears.',
            point4: 'Leave it on for 15 minutes.'
        },
        precaution: {
            point1: 'Keep out of your mouth, nose, and eyes (may burn).',
            point2: 'Keep eyes closed tightly.'
        }
    },

    //Insect Killer
    {
        id: 'cobra-extra-aerosol-insect-killer',
        name: "COBRA EXTRA AEROSOL INSECT KILLER",
        tagline: 'Keep your house hygienic & germs free',
        description: "Cobra Aerosol Insect Killer kills 99.9% of home insects like cockroaches, mosquitoes, and crickets in 1 spray and protects for up to 6 months indoors.",
        image: Aerosal,
        images: [Aerosal, Aerosal2, Aerosal3, Aerosal4, Aerosal5],
        type2: 'Regular',
        table: {
            size: '300ml',
            cartonPack: '48 Pieces Carton',
            size2: '400ml',
            cartonPack2: '48 Pieces Carton',
            size3: '500ml',
            cartonPack3: '24 Pieces Carton',
            size4: '600ml',
            cartonPack4: '24 Pieces Carton',
            size5: '750ml',
            cartonPack5: '12 Pieces Carton',
        },
        sku: '1001-A',
        category: 'Insect-killer',
        use:
        {
            point1: 'Cover food, cooking utensils, and other personal items from the area to be treated.',
            point2: 'Thoroughly clean kitchen benches before preparing food.',
            point3: 'Wash hands after using the spray.'
        },
        precaution: {
            point1: 'Follow label directions carefully.',
            point2: ' Never eat, smoke, drink, or chew while spraying.',
            point3: 'Avoid applying surface sprays where inflammable substances are present.'
        }
    },
    {
        id: 'cobra-extra-strong-aerosol-insect-killer',
        name: "COBRA EXTRA STRONG AEROSOL INSECT KILLER",
        tagline: 'Silently but will kill brutally',
        description: "Cobra Extra Strong Aerosol Insect Killer kills all home insects and keeps the house protected for up to 11 months",
        image: ExtraStrongAerosal,
        type2: 'Red / Ouderless',
        table: {
            size: '500ml',
            cartonPack: '24 Pieces Carton',
        },
        sku: '1001-D',
        category: 'Insect-killer',
        use:
        {
            point1: 'Cover food, cooking utensils, and other personal items from the area to be treated.',
            point2: 'Thoroughly clean kitchen benches before preparing food.',
            point3: 'Wash hands after using the spray.'
        },
        precaution: {
            point1: 'Follow label directions carefully.',
            point2: ' Never eat, smoke, drink, or chew while spraying.',
            point3: 'Avoid applying surface sprays where inflammable substances are present.'
        }
    },
    {
        id: 'cobra-powder-insect-killer',
        name: "Cobra Powder Insect Killer",
        tagline: 'Har kone main thoda thoda chore naw keero ka nishaan bhi zara sa',
        description: "Cobra Powder Insect Killer is available in 3 variants: Red, Blue & Green. Fine powder which contains active ingredients that are toxic to insects like roaches, ants, fleas, etc.",
        image: KillerPowder,
        type1: 'Red',
        type2: 'Blue',
        type3: 'Green',
        table: {
            size: '133gm',
            cartonPack: '96 Pieces Carton',
        },
        sku: '1001-C',
        category: 'Insect-killer',
        use:
        {
            point1: 'Apply the powder in areas where insects are present or likely to appear.',
            point2: 'Reapply as needed.',
        },
        precaution: {
            point1: 'Keep out of reach of children.',
            point2: 'Avoid contact with eyes and skin.',
            point3: ' Wash hands after use.'
        }
    },
    {
        id: 'bomb-aerosol-insect-killer',
        name: 'BOMB AEROSOL Insect-killer',
        tagline: `Target locked! 123 GO!!!.....BOOM…The rivals are finished can't tease you anymore!!!`,
        description: "Bomb Aerosol Insect Killer kills all harmful home insects like cockroaches, mosquitoes, crickets, etc., with double speed and protects for up to 9 months.",
        image: Bomb,
        type1: 'Regular',
        table: {
            size: '325ml',
            cartonPack: '48 Pieces Carton',
            size2: '500ml',
            cartonPack2: '24 Pieces Carton'
        },
        sku: '1002-A',
        category: 'Insect-killer',
        use:
        {
            point1: 'Cover food, cooking utensils, and other personal items from the area to be treated.',
            point2: 'Thoroughly clean kitchen benches before preparing food.',
            point3: 'Wash hands after using the spray.'
        },
        precaution: {
            point1: 'Follow label directions carefully.',
            point2: ' Never eat, smoke, drink, or chew while spraying.',
            point3: 'Avoid applying surface sprays where inflammable substances are present.'
        }
    },
    {
        id: 'cobra-aerosol-lizard-killer',
        name: 'COBRA AEROSOL LIZARD KILLER',
        tagline: 'Scared to even look at?? Don’t worry just spray it around this creepy creature will be dead in no time!',
        description: "Cobra Aerosol Insect Killer kills 99.9% Lizards in 1 spray and protects for up to 6 months indoors",
        image: Lizard,
        type1: 'Regular',
        table: {
            size: '300ml',
            cartonPack: '48 Pieces Carton',
        },
        sku: '1001-E',
        category: 'Insect-killer',
        use:
        {
            point1: 'Cover food, cooking utensils, and other personal items from the area to be treated.',
            point2: 'Thoroughly clean kitchen benches before preparing food.',
            point3: 'Wash hands after using the spray.'
        },
        precaution: {
            point1: 'Follow label directions carefully.',
            point2: ' Never eat, smoke, drink, or chew while spraying.',
            point3: 'Avoid applying surface sprays where inflammable substances are present.'
        }
    },
    {
        id: 'cobra-xtreme-insecticide-spray',
        name: 'COBRA XTREME 7X- INSECTICIDE SPRAY',
        tagline: 'Keera ho ya makora ya ho kaan main bhinbhinane wale machar sb ka khatma with Cobra Xtreme. Extremely deadly!!!',
        description: "Available in 3 Variants, Extreme – 7X Multi Action, Crawling, Flying. Kills the insects with fast action and prevent them to get back into house upto 14 months.",
        image: Insecticide,
        images: [Insecticide, Insecticide2, Insecticide3, Insecticide4, Insecticide5, Insecticide6],
        type1: 'Blue / Crawling',
        type2: 'Purple / Flying',
        type3: 'Green/ Multi-action',
        table: {
            size: '320ml',
            cartonPack: '48 Pieces Carton',
            size2: '500ml',
            cartonPack2: '24 Pieces Carton',
        },
        sku: '1001-D',
        category: 'Insect-killer',
        use:
        {
            point1: 'Cover food, cooking utensils, and other personal items from the area to be treated.',
            point2: 'Thoroughly clean kitchen benches before preparing food.',
            point3: 'Wash hands after using the spray.'
        },
        precaution: {
            point1: 'Follow label directions carefully.',
            point2: ' Never eat, smoke, drink, or chew while spraying.',
            point3: 'Avoid applying surface sprays where inflammable substances are present.'
        }
    },

];

export default mockProducts;
