import React from 'react'
import { Link } from 'react-router-dom'

function FooterAbout(props) {
    return (
        <div className="footer-widget">
            <div className="footer-logo">
                <Link target='_blank' to="index.html"><img src={props.logo} className="img-fluid" /></Link>
            </div>
            <div className="footer-text">
                <p>Cobra Hygiene (PVT) Ltd, is one of the leading manufacturers, importer and distributor of household, personal care products in Pakistan. Cobra Hygiene (PVT) Ltd, serves its customers with the maximum effective ways of import.</p>
            </div>
            <div className="footer-social-icon">
                <span>Follow us</span>
                <Link target='_blank' to="https://www.facebook.com/cobrahygiene/">
                    <i className="fab fa-facebook-f facebook-bg"></i>
                </Link>
                <Link target='_blank' to="https://www.instagram.com/cobrahygiene/">
                    <i className="fa-brands fa-instagram insta-bg"></i>
                </Link>
                <Link target='_blank' to="https://www.pinterest.fr/cobrahygiene/">
                    <i className="fa-brands fa-pinterest pinterest-bg"></i>
                </Link>
                <Link target='_blank' to="https://www.tiktok.com/@cobra_hygiene/">
                    <i className="fab fa-tiktok tiktok-bg"></i>
                </Link>
                <Link target='_blank' to="https://www.youtube.com/channel/UCQhwYzdJp4IqF0MEE9YehMQ">
                    <i className="fab fa-youtube youtube-bg"></i>
                </Link>
            </div>
        </div>
    )
}

export default FooterAbout